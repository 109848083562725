import { json } from '@remix-run/cloudflare'
import { Form, useActionData } from '@remix-run/react'
import type { ActionFunctionArgs } from '@remix-run/cloudflare'
import { createSupabaseServerClient } from '~/utils/supabase.server'
import type { CloudflareAppLoadContext } from '~/types/cloudflare'

export const action = async ({ request, context }: ActionFunctionArgs) => {
  const { supabaseClient, headers } = createSupabaseServerClient(request, context as CloudflareAppLoadContext)
  const formData = await request.formData()
  const { error } = await supabaseClient.auth.signInWithOtp({
    email: formData.get('email') as string,
    options: {
      shouldCreateUser: false,
      emailRedirectTo: `new URL(request.url).origin}/auth/callback`,
    },
  })

  if (error) {
    return json({ success: false }, { headers })
  }
  return json({ success: true }, { headers })
}

const SignIn = () => {
  const actionData = useActionData<typeof action>()
  return (
    <>
      {!actionData?.success ? (
        <Form method="post">
          <input type="email" name="email" placeholder="Your Email" required />
          <br />
          <button type="submit">Sign In</button>
        </Form>
      ) : (
        <h3>Please check your email.</h3>
      )}
    </>
  )
}

export default SignIn